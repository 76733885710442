/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* -------------------------- HOME ----------------------------- */
.Home {
    background-image: url('./wallpaper.png'); /* Update with the correct path to the image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    box-sizing: border-box;
}

  /* Style for the left and right sections */
.left, .right {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
  
  /* Media query for larger screens */
@media (min-width: 768px) {
    .Home {
      flex-direction: row;
    }
  
    .left {
      width: 55%;
    }
  
    .right {
      width: 45%;
    }
}

html {
  scroll-behavior: smooth;
}


/* -------------------------- ABOUT ME ----------------------------- */
.About {
  display: flex;
  background-image: url('./plane_wallpaper.png'); /* Ensure correct path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: row; /* Default to row layout */
  overflow-y: hidden; /* Hide horizontal overflow */
  padding: 0;
  min-height: 100vh; /* Full viewport height */
}

@media (max-width: 768px) {
  .About {
    flex-direction: column;
  }

  .About .left, .About .right {
    width: 100%;
    min-height: 50vh; /* Adjust height as needed */
  }

  /* Optional: Adjust padding for smaller screens if necessary */
  .content-area {
    padding-top: 30px; 
  }
}

.About .left {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  width: 30%;
  min-height: 100vh;
  margin-left: 50px;
}

.About .right {
  width: 70%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  margin: 55px;
}

/* -------------------------- ABOUT ME ----------------------------- */
.Experience {
  display: flex;
  background-image: url('./plane_wallpaper.png'); /* Update with the correct path to the image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    box-sizing: border-box;
}

.Projects {
  display: flex;
  background-image: url('./plane_wallpaper.png'); /* Update with the correct path to the image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    box-sizing: border-box;
}

/* -------------------------- SKILLS ----------------------------- */
.skill-card {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 15px;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.skill-icon {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.skill-name {
  margin: 0;
  font-size: 1rem;
}
/* ------------------------------ */

