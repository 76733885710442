.header {
  height: rem(60px);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  position: fixed; /* Fix the position at the top */
  top: 0; /* Align to the top edge */
  left: 0; /* Align to the left edge */
  right: 0; /* Stretch across the full width of the viewport */
  /* background-image: url("./wallpaper.png");   */
  background-color: #e2f4fe;
  z-index: 1000;
  padding: 5px;
}

body {
  padding-top: 30px; /* Adjust this value to match the height of your navbar */
}
  
.link {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 1000;
  font-size: var(--mantine-font-size-sm);
}
  
  .subLink {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-md);
  }
  
  .dropdownFooter {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    margin: calc(var(--mantine-spacing-md) * -1);
    margin-top: var(--mantine-spacing-sm);
    padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
    padding-bottom: var(--mantine-spacing-xl);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  }

  .activeLink {
    border-bottom: 4px solid #238be6; /* Underline effect */
  }

  /* Home.module.css */
.animatedButton {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  will-change: transform, box-shadow; /* Optimize for animations */
}

.animatedButton:hover,
.animatedButton:focus { /* Also add focus for accessibility */
  transform: scale(1.05); /* Slightly enlarge the button */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}
