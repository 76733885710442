.footer {
    margin-top: rem(120px);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
    background-image: url('../plane_wallpaper.png'); /* Update with the correct path to the image */
    margin-bottom: 10px;
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--mantine-spacing-md) var(--mantine-spacing-md);
  }
