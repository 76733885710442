.wrapper {
  position: relative;
  padding-top: rem(120px);
  padding-bottom: rem(80px);

  @media (max-width: 768px) {
    padding-top: rem(80px);
    padding-bottom: rem(60px);
  }
}

.inner {
  position: relative;
  z-index: 1;
  margin-top: -100px;
}

.dots {
  position: absolute;
  color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));

  @media (max-width: 768px) {
    display: none;
  }
}

.dotsLeft {
  left: 0;
  top: 0;
}

.title {
  text-align: center;
  font-weight: 800;
  font-size: rem(40px);
  letter-spacing: -1px;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  margin-bottom: var(--mantine-spacing-xs);
  font-family:
    Greycliff CF,
    var(--mantine-font-family);

  @media (max-width: 768px) {
    font-size: rem(28px);
    text-align: left;
  }
}

.highlight {
  color: light-dark(var(--mantine-color-blue-6), var(--mantine-color-blue-4));
}

.description {
  text-align: center;

  @media (max-width: 768px) {
    text-align: left;
    font-size: var(--mantine-font-size-md);
  }
}

.controls {
  margin-top: var(--mantine-spacing-lg);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  
}

.control {
  &:not(:first-of-type) {
    margin-left: var(--mantine-spacing-md);
  }

  @media (max-width: 768px) {
    height: rem(42px);
    font-size: var(--mantine-font-size-md);

    &:not(:first-of-type) {
      margin-top: var(--mantine-spacing-md);
      margin-left: 0;
    }
  }
}

h1 {
  font-size: 45px;
}

@keyframes typewriter {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blinkCaret {
  50% { border-color: transparent; }
}

.typewriter {
  display: inline-block;
  overflow: hidden;
  border-right: .15em solid black; /* The typewriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto;
  letter-spacing: .15em;
  animation: typewriter 4s steps(40) 1s forwards,
             blinkCaret .75s step-end infinite;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: aqua;
}

/* Home.module.css */
.animatedButton {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  will-change: transform, box-shadow; /* Optimize for animations */
}

.animatedButton:hover,
.animatedButton:focus { 
  transform: scale(1.05); 
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.buttonContainerHome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .buttonContainerHome {
      flex-direction: column;
      justify-content: flex-start; 
      align-items: flex-start; 
  }

  
}

@media (max-width: 767px) {
  .mobileTitleBreak {
      display: block;
  }

  .inner {
    position: relative;
    z-index: 1;
    margin-top: -275px;
  }
}