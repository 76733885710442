.contact {
    height: 70vh;
    background-image: url('./plane_wallpaper.png');
    display: flex; /* Enable Flexbox */
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
}

.contactForm {
    max-width: 800px;
    width: 100%; 
    margin: 0 auto; 
    padding: 20px;
    box-sizing: border-box;
    opacity: 0;
    animation: fadeInAnimation 1s forwards;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 768px) {
    .contactForm {
        max-width: 95%;
        padding: 15px;
    }
}

@keyframes fadeInAnimation {
    to {
        opacity: 1;
    }
}

.contactForm:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}